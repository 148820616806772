/***********************************************************************
 utils contains useful utility functions that are commonly used by 
 this MPA
************************************************************************/
import $ from 'jquery';
import path from 'path';
import axios from 'axios';
import { prepareHTML } from '../config/form/utils';
import { pdf, previous, returnButton } from './components';
import {
    domain,
    month,
    route,
    step,
    buttonFontFamily,
    buttonIcons,
    buttonTitleWidth,
    iconPreference,
    buttonColors,
    twoPerRowStr,
    twoPerRowSubstr,
    threePerRowStr,
    threePerRowSubstr,
    fourPerRowStr,
    fourPerRowSubstr,
} from './config';

/**
 * function automates hidden input to forms that it is imported to, including the on click return event
 * @param {object} account - the account for the API call
 * @param {object} elementID - contains the div ID of the form input
 * @param {object} value - contains values to be inserted to the form input
 * @param {string} form - the form for the API call
 * @param {string} myReturn - the page that the return button will go to on click
 */
function formAutomation(account, elementID, value, form, returnPage) {
    $('#interface').html(`${returnButton()}`); 
    if(form == "Social media handles" || form == "Refund") {
        $(elementID.institution).val(value.institution);
    } else {
        $(elementID.institution).empty();
        $(elementID.institution).append(`
            <option value="${value.institution}" selected>${value.institution}</option>
        `);
    }
    $(elementID.program).val(value.program);
    $(elementID.term).val(value.term);
    $(elementID.pid).val(value.pid);
    $('#return').on('click', () => { cacheClear(account), window.location.href = returnPage });
    console.log(`institution: ${$(elementID.institution).val()}\nprogram: ${$(elementID.program).val()}\nterm: ${$(elementID.term).val()}\nppid: ${$(elementID.pid).val()}\n`);
    cachePost(account, form);
}

/**
 * Utility API function for the mapping of api routes for tracked items in v1/Participant Model {@link https://github.com/chizuo/evp-portal-api/blob/main/docs/v1/participant.md}
 * @param {string} item - the item for the API call
 * @param {object} account - the account for the API call
 * @param {number} retries - the amount of times an API attempt had to be made
 */
async function trackingAPI(item, account, retries = 0) {
    try {
        const response = await axios.put(`${domain}/${route.get(item)}`, { uid: account.uid, program: account.program, semester: account.semester, year: account.year });
        let isGuide = item.includes('Guide');
        let isExample = item.includes('example');
        if (step.get(item) === 'step1') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step1[item]++;
        }
        else if (step.get(item) === 'step2') {
            item = isGuide ? 'Guide' : item;
            item = isExample ? 'Examples' : item;
            account.tracked.step2[item]++;
        }
        else if (step.get(item) === 'step3') {
            item = isGuide ? 'Guide' : item;
            account.tracked.step3[item]++;
        }
        else if (step.get(item) === 'step4') {
            item = isGuide ? 'Guide' : item;
            item = isExample ? 'Examples' : item;
            account.tracked.step4[item]++;
        }
        else if (step.get(item) === 'budgetManagement') {
            item = isGuide ? 'Guide' : item;
            account.tracked.budgetManagement[item]++;
        }
        else console.error(`Section for ${item} does not exist in the configuration`);
        console.log(JSON.parse(JSON.stringify(account)))
        sessionStorage.setItem('evp_participant', JSON.stringify(account));
    } catch (e) {
        ++retries;
        console.error(e.message);
        sleep(retries * 250).then(() => { if (retries < 5) trackingAPI(item, account, retries); });
    }
}

/**
 * Creates the PDF reader interface for the portal
 * @param {string} path - path of the PDF
 * @param {function} prevUi - function that loads the previous interface
 */
function pdfReader(path, prevUi) {
    $('#interface').html(`
        ${returnButton()}
        ${pdf(path)}
    `);
    previous(prevUi);
}

/**
 * Creates the YouTube video player interface for the portal
 * @param {string} url - url of the video
 * @param {function} prev - function that loads the previous interface
 */
function youtubePlayer(url, prev) {
    function youtubeEmbedder(url) {
        if (url.includes("youtube.com/embed/")) {
            return url;
        }

        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);

        if (match && match[1]) {
            const videoId = match[1];
            const embedUrl = `https://www.youtube.com/embed/${videoId}?si=6Nu7nYFFGI8CCarh`;
            return embedUrl;
        } else {
            // Return null or an appropriate message if the URL is invalid
            return null;
        }
    }

    const screenWidth = $(window).width();
    const screenHeight = $(window).height();

    // Calculate the dimensions for the iframe
    const iframeWidth = screenWidth * 0.8;  // 80% of screen width
    const iframeHeight = screenHeight * 0.5; // 50% of screen height
    $('#interface').html(`
        ${returnButton()}
        <center class="m-3">
            <iframe width="${iframeWidth}" height="${iframeHeight}" src="${youtubeEmbedder(url)}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <center>
    `);

    previous(prev);
    $('html, body').animate({ scrollTop: $("#interface").offset().top }, 500);
    // sleep(15 * 1000).then(() => trackingAPI('Kickoff material')); // TODO: this web request fails. Should the item be hard-coded?
}

/**
 * Utility function to dynamically set the iframe based on the window.
 * @param {integer} h - integer representing a percentage to be set for height based on window size, defaults to 80.
 * @param {integer} w - integer representing a percentage to be set for width based on window size, defaults to 80.
 * @returns {Object} returns the object { iframeHeight, iframeWidth }
 */
function iFramer(h = 80, w = 80) {
    const height = h / 100;
    const width = w / 100;
    // Get the new screen width and height
    const screenWidth = $(window).width();
    const screenHeight = $(window).height();

    // Calculate the new dimensions for the iframe
    const iframeWidth = screenWidth * width;
    const iframeHeight = screenHeight * height;

    // Set the new dimensions
    $("iframe").css({
        "width": iframeWidth,
        "height": iframeHeight
    });

    return { iframeHeight, iframeWidth }
}

/**
 * Returns the full name of the month.
 * @param {integer} m - number representing the month, starting at 0 as January to 11 as December.
 * @param {integer} d - number representing the month, starting at 0 as January to 11 as December.
 * @param {integer} y - number representing the month, starting at 0 as January to 11 as December.
 * @returns {string} formatted date as a string based in parameters provided, e.g. January 1, 2000, returns an empty string if no parameters are provided
 */
function date(m = -1, y = -1, d = 0) {
    const d30 = new Set([3, 5, 8, 10]);

    let md = m >= 0 && m <= 11 ? month.get(m) : ''; // validate m & gets month of m

    if (d && md.length) { // validates d if d was provided & m was correctly provided
        if (m === 1 && d > 28) { // prevents incorrect February dates, including leap years
            d = 28;
        } else if (d30.has(m) && d > 30) { // correct max day on months with 30 days
            d = 30;
        } else { // correct max day of months with 31 days
            if (d > 31) d = 31;
        }
        md += ' ' + d; // adds the day
    }

    let format = md.length && d ? ', ' : ' '; // adds comma if m,d are provided.
    if (y) md += format + y;
    return md;
}

/**
 * Function that pauses the thread for the indicated length, in milliseconds, that is passed in
 * @param {integer} ms - length of time, in milliseconds, that this function should cause a thread to sleep.
 * @returns {Promise} resolves after the parameter ms time
 */
function sleep(ms = 250) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Function that captures the module where the help icon was clicked to launch the help
 * form application and adds the step and section to the account object to store in
 * sessionStorage for the help form application to retrieve.
 * @param {string} step
 * @param {string} section
 * @param {object} account
 */
function help(step, section, account) {
    account.step = step;
    account.section = section;
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
    sleep(1000).then(() => window.location.href = 'help_request');
}

/**
 * Function that will make the interface run a transition animation to a new DOM element or new document
 * @param {function} next - callback function that either changes the document or changes the #interface element.
 */
function transition(next) {
    let animationCount = $('.right').length + $('.left').length + $('.zoom').length;
    if (animationCount === 0) {
        next();
        return;
    }

    function animationEnded() {
        animationCount--;
        if (animationCount === 0) {
            next();
        } else if (animationCount < 0) {
            throw new Error('animationCount < 0');
        }
    }

    $('.right').each(function () {
        $(this).removeClass('animate__animated animate__backInRight animate__backInLeft');
        $(this).addClass('animate__animated animate__backOutRight');
        $(this).one('animationend', animationEnded);
    });

    $('.left').each(function () {
        $(this).removeClass('animate__animated animate__backInLeft');
        $(this).addClass('animate__animated animate__backOutLeft');
        $(this).one('animationend', animationEnded);
    });

    $('.zoom').each(function () {
        $(this).removeClass('animate__animated animate__zoomIn');
        $(this).addClass('animate__animated animate__zoomOut');
        $(this).one('animationend', animationEnded);
    });
}

function cachePost(account, form) {
    account.post = form;
    console.log("cached account: ", account);
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
}

function cacheClear(account) {
    if(account.post) delete account.post;
    if(account.receipt) delete account.receipt;
    if(account.section) delete account.section;
    sessionStorage.setItem('evp_participant', JSON.stringify(account));
}

/**
 * getRowWidth determines the maximum number of buttons that should be on each row of the
 * UI.
 * @param {Element[]} elements
 * @returns {number}
 */
function getRowWidth(elements) {
    let elementCount = 0;
    // count only unhidden elements
    for (const el of elements) {
        if (!el.hidden) {
            elementCount++;
        }
    }

    if (elementCount <= 2) {
        return 2;
    } else if (elementCount % 4 === 0) {
        return 4;
    } else {
        return 3;
    }
}

/**
 * @typedef {object} Element
 * @property {'href'|'openUri'|'menu'|'frame'|'youtube'|'pdf'|'help'} type
 * @property {string} title
 * @property {boolean|undefined} comingSoon
 * @property {boolean|undefined} hidden
 * @property {string|undefined} iconPath - absolute file path.
 * @property {string|undefined} uri - required if type is href, openUri, menu, frame,
 * youtube, pdf, or help.
 * @property {string|undefined} trackingName
 * @property {object|undefined} button - only available when type is frame.
 * @property {string} button.title
 * @property {string} button.uri - the URI to set location.href to on button click.
 * @property {
 *   'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark'|'link' }
 *   button.variant - https://getbootstrap.com/docs/5.3/components/buttons/#variants
 * @property {string|undefined} step - required if type is help.
 * @property {string|undefined} section - required if type is help.
 */

/**
 * @typedef {object} UiData
 * @property {string} path - the folder path for resources of UI data.
 * @property {Element[]} elements
 */

/**
 * uiBuilder creates a page of buttons and replaces the interface.
 * @param {UiData} uiData
 * @param {function(): void} ui
 * @param {function(): void} prevUi
 * @param {any} account
 * @param {object} menus
 * @returns {void}
 */
function uiBuilder(uiData, ui, prevUi, account, menus) {
    /** @type {Element[]} */
    const elements = uiData.elements;

    $('#interface').html(returnButton());
    $('#return').on('click', prevUi);

    const perRow = getRowWidth(elements);
    let buttonClass = 'g-0 m-2 animate__animated animate__backInLeft icon-15 glow-button left';
    if (perRow === 4) {
        buttonClass += ' col-sm-2';
    } else if (perRow === 3) {
        buttonClass += ' col-sm-3';
    } else {
        buttonClass += ' col-sm-4';
    }

    let i = 0;
    while (i < elements.length) {
        const rowId = 'row-with-element-' + i;
        $('#interface').append(`<div id="${rowId}" class="row mt-1"></div>`);
        for (let col = 0; col < perRow && i < elements.length; col++, i++) {
            if (elements[i].hidden) {
                col--;
                continue;
            }

            let buttonStyle = `font-family: ${buttonFontFamily};`;
            let buttonIconSrc = '';
            if (iconPreference && buttonIcons.length > 0) {
                // use a PNG
                buttonIconSrc = buttonIcons[0];
                buttonIcons.push(buttonIcons.shift());
            } else {
                // use a color
                buttonStyle += `background-color: ${buttonColors[0]};`;
                buttonColors.push(buttonColors.shift());
            }

            buttonBuilder(
                elements[i],
                perRow,
                buttonClass,
                buttonStyle,
                buttonIconSrc,
                rowId,
                uiData.path,
                ui,
                account,
                menus,
            );
        }
    }
}

/**
 * buttonBuilder creates a UI button and appends it to a row in the interface.
 * @param {Element} element
 * @param {number} perRow
 * @param {string} buttonClass
 * @param {string} buttonStyle
 * @param {any} buttonIconSrc
 * @param {string} rowId
 * @param {string} uiDataPath
 * @param {function(): void} ui
 * @param {any} account
 * @param {object} menus
 * @returns {void}
 */
function buttonBuilder(
    element,
    perRow,
    buttonClass,
    buttonStyle,
    buttonIconSrc,
    rowId,
    uiDataPath,
    ui,
    account,
    menus,
) {
    let title = element.title;
    if (element.comingSoon) {
        buttonClass += ' dark-50';
        title += ' coming soon';
    }
    const buttonId = createHtmlElementId(title);
    const fontSize = getFontSize(title, perRow);
    const buttonTitleStyle = `
        font-size: ${fontSize}%;
        width: ${buttonTitleWidth}%;
    `;

    $('#' + rowId).append(`
        <div id="${buttonId}" class="${buttonClass}" style="${buttonStyle}">
            <div class="square-container-normal">
                <div class="title-overlay text-center" style="${buttonTitleStyle}">
                    ${title}
                </div>
            </div>
        </div>
    `);
    if (buttonIconSrc) {
        $('#' + buttonId + ' div').prepend(`
            <img src=${buttonIconSrc} class="fill icon-15" />
        `);
    }

    if (element.comingSoon) {
        return;
    }

    $('#' + buttonId).on('click', () => {
        if (element.trackingName) {
            trackingAPI(element.trackingName, account);
        }

        switch (element.type) {
            case 'href':
                transition(() => window.location.href = element.uri);
                break;
            case 'openUri':
                window.open(element.uri);
                break;
            case 'youtube':
                youtubePlayer(element.uri, ui);
                break;
            case 'menu':
                menuBuilder(element, buttonId, ui, account, menus);
                break;
            case 'frame':
                frameBuilder(element, ui);
                break;
            case 'pdf':
                transition(() => {
                    const pdfPath = path.join(uiDataPath, element.uri);
                    pdfReader(pdfPath, ui);
                });
                break;
            case 'help':
                transition(() => help(element.step, element.section, account));
                break;
            default:
                throw new Error('Unknown element type: ' + element.type);
        }
    });
}

/**
 * menuBuilder attempts to load a JSON file and replaces the interface with a new menu
 * created from the JSON file.
 * @param {Element} element
 * @param {string} buttonId
 * @param {function(): void} prev
 * @param {any} account
 * @param {object} menus
 * @returns {boolean} - whether successful.
 */
function menuBuilder(element, buttonId, prev, account, menus) {
    function menuUi() {
        /** @type {UiData} */
        const uiData = menus[element.uri];
        if (uiData === undefined) {
            console.error(
                `uiData is undefined because "menus" does not contain "${element.uri}"`
            );
            errDisableButton(buttonId);
            return false;
        }

        transition(() => uiBuilder(uiData, menuUi, prev, account, menus));
    }

    try {
        menuUi();
    } catch (err) {
        console.error('menuUi: ' + err);
        errDisableButton(buttonId);
        return false;
    }

    return true;
}

/**
 * frameBuilder replaces the interface with an iframe.
 * @param {Element} element
 * @param {function(): void} prev
 */
function frameBuilder(element, prev) {
    const { iframeHeight, iframeWidth } = iFramer();
    console.log(`height:${iframeHeight} & width:${iframeWidth}`);

    const buttonGroupId = createHtmlElementId(element.title) + '-button-group';

    $('#interface').html(`
        <center class="animate__animated animate__zoomIn">
            <div id="${buttonGroupId}" class="btn-group w-25 m-2" role="group" aria-label="mini-menu">
                <button type="button" id="return" class="btn btn-primary w-25">
                    Return
                </button>
            </div>
            <iframe src="${element.uri}" width="${iframeWidth}" height="${iframeHeight}" frameborder="1">
            </iframe>
        </center>
    `);

    if (element.button) {
        const buttonId = createHtmlElementId(element.title) + '-button';
        const title = element.button.title || 'click';
        const variant = element.button.variant || 'secondary';
        $('#' + buttonGroupId).append(`
            <button type="button" id="${buttonId}" class="btn btn-${variant} w-25">
                ${title}
            </button>
        `);
        if (element.button.uri) {
            $('#' + buttonId).on('click', () => window.location.href = element.button.uri);
        }
    }

    previous(prev);
}

/**
 * createHtmlElementId replaces certain characters with dashes and may prepend a letter
 * to create a valid HTML element ID compatible with jQuery.
 * @param {string} str
 * @returns {string}
 */
function createHtmlElementId(str) {
    return str
        .replaceAll('&', 'and')
        .replaceAll(/[ \.\/\:\[\]\,\=\@\'\"\?~!\$%^\*\(\)\+;><\\\{\}|`#]/g, '-')
        .replace(/^([^a-zA-Z].*)/, 'a$1') // must start with a letter
}

/**
 * getFontSize determines the percentage font size to use depending on the title and the
 * number of buttons per row.
 * @param {string} title
 * @param {number} perRow
 * @returns {number}
 */
function getFontSize(title, perRow) {
    if (perRow === 2) {
        return _getFontSize(title, twoPerRowStr, twoPerRowSubstr);
    } else if (perRow === 3) {
        return _getFontSize(title, threePerRowStr, threePerRowSubstr);
    } else {
        return _getFontSize(title, fourPerRowStr, fourPerRowSubstr);
    }
}

/**
 * @param {string} title
 * @param {Map<number, number>} perRowStrMap
 * @param {Map<number, number>} perRowSubstrMap
 * @returns {number}
 */
function _getFontSize(title, perRowStrMap, perRowSubstrMap) {
    let strValue = undefined;
    for (const [key, value] of perRowStrMap) {
        if (title.length <= key) {
            strValue = value;
            break;
        }
    }

    let longestWordLen = 0;
    const words = title.split(' ');
    for (let i = 0; i < words.length; i++) {
        const len = words[i].length;
        if (len > longestWordLen) {
            longestWordLen = len;
        }
    }

    let substrValue = undefined;
    for (const [key, value] of perRowSubstrMap) {
        if (longestWordLen <= key) {
            substrValue = value;
            break;
        }
    }

    if (strValue === undefined && substrValue === undefined) {
        return 100;
    } else if (strValue === undefined) {
        return substrValue;
    } else if (substrValue === undefined) {
        return strValue;
    } else {
        return strValue < substrValue ? strValue : substrValue;
    }
}

/**
 * errDisableButton disables a button, makes it darker, and replaces its title with
 * "Error".
 * @param {string} buttonId
 * @returns {void}
 */
function errDisableButton(buttonId) {
    $('#' + buttonId)
        .addClass('dark-50')
        .html(`
            <div class="square-container-normal">
                <div class="overlay-text text-center display-4">
                    Error
                </div>
            </div>`)
        .prop('onclick', null).off('click');
}

export {
    prepareHTML,
    formAutomation as automate,
    trackingAPI,
    cachePost,
    cacheClear,
    date,
    help,
    iFramer,
    pdfReader,
    sleep,
    transition,
    uiBuilder,
};
