import $ from 'jquery';

function prepareHTML() {
    const footerContent = '<div id="footer"></div>';
    $('body').append(footerContent);
}

export {
    prepareHTML
}
